import * as React from "react";
import { Link } from "gatsby-theme-material-ui";
import { StaticImage } from "gatsby-plugin-image";
import Box from '@mui/material/Box';
import Layout from "../components/layout";
import Seo from "../components/seo";
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import dayjs, { Dayjs } from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import ChartLayout from "../components/ChartStructure"
import LifeNumber from "../components/LifeNumber"
import useMediaQuery from '@mui/material/useMediaQuery';
import TextField from '@mui/material/TextField';
import { useTheme, createTheme } from '@mui/material/styles';
import { useState } from 'react'
import DatePicker from '../components/DatePicker'
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { NumberChip as Chip } from '../components/Chip';
import MobileNumber from "../components/MobileNumber"
import { East } from '@mui/icons-material/';
import NumberChart from "../components/NumberChart"
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';
import { ThemeProvider } from '@mui/styles'
import CardHeader from '@mui/material/CardHeader';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import Parse from '../vendor/Parse'
import PostList from '../components/PostList'
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import Menu from '../components/Menu'
import ThreeCol from '../components/ThreeColLayout'
import useAuth from "../hooks/userAuth";
import { graphql } from 'gatsby'
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
const { useEffect } = React;


const myTheme = createTheme({

})

const RightCol = ({ post, isSM, onChange }) => {
    const [value, setValue] = useState("accupunture")
    useEffect(() => {
        onChange && onChange(value)
    }, [value])

    return <Box display={"flex"} flexDirection={"column"} alignItems={"end"} padding={isSM ? "0 32px 0 32px" : "0"}>
        <h3>Manage Item</h3>
        <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Age</InputLabel>
            <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={value}
                label="Age"
                onChange={(e) => setValue(e.target.value)}
            >
                <MenuItem value={""}>All</MenuItem>
                <MenuItem value={"accupunture"}>Accupunture</MenuItem>
                <MenuItem value={"numberFormula"}>NumberFormula</MenuItem>
                <MenuItem value={"herbalFormula"}>HerbalFormula</MenuItem>
            </Select>
        </FormControl>
        <Divider />
    {
        post.map(e => <a href="">
            {
                e?.data?.title
            }
        </a>)
    }
    </Box >
}


const IndexPage = () => {
    const { isAuth, env } = useAuth()
    const [post, setPosts] = useState([])
    const [preview, setPreview] = useState('')
    const [number, setNumber] = useState('')
    const [day, setDate] = useState('')
    const [month, setMonth] = useState('')
    const [year, setYear] = useState('')
    const [elements, setElements] = useState(null)
    const [accordion, setAccordion] = useState([])
    const theme = useTheme();
    const [numberInfo, setNumberInfo] = useState()
    const isSM = useMediaQuery(theme.breakpoints.up('sm'));
    const [list, setList] = useState();
    const [currentName, setCurrentName] = useState("")

    const refreshData = async (type = ``) => {
        const result = await Parse.Cloud.run('queryItems', {
            type: `post_public-${env}`,
            query: {
                equalTo: ['name', type],
            },
        })
        setPosts(result?.map((e) => e.toJSON()) || [])
    }

    useEffect(async () => {
        refreshData(currentName)
    }, [currentName])

    const handleDelete = async (post) => {
        await Parse.Cloud.run('deleteItems', {
            ids: [post._objectId]
        })
        refreshData(currentName)
    }

    const handlePost = async (post) => {
        if(post._objectId) {
            await Parse.Cloud.run('updateItem', {
                type: `post_public-${env}`,
                objectId: post._objectId,
                data: {
                    ...post,
                },
                name: currentName
            })
        } else {
            await Parse.Cloud.run('createItem', {
                type: `post_public-${env}`,
                data: {
                    ...post,
                },
                name: currentName
            })
        }

        refreshData(currentName)
    }

    return (
        <>
            <ThreeCol env={env} right={<RightCol isSM={isSM} post={post} onChange={(e) => setCurrentName(e)} />}>
                <Seo title="Home" />
                <Box display="flex" justifyContent="center" flexDirection="column" gap="16px">
                    <PostList list={post} isAuth={isAuth()} handleDelete={handleDelete} onPost={handlePost} />
                </Box>
            </ThreeCol>
        </>)
}

export default IndexPage

